import React from 'react';

import { Text, TextTypography, Dialog, DialogProps } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './Modal.st.css';

type IModal<P> = React.FC<P> & {
  Buttons: React.FC;
  Header: React.FC<ModalHeaderProps>;
};

export enum ModalSkin {
  REACTIONS = 'reactions',
  NEW_POST = 'newPost',
}

interface ModalHeaderProps {
  title: string;
  subtitle?: string;
}

export interface ModalProps extends DialogProps {
  className?: string;
  skin?: ModalSkin;
}

export const Modal: IModal<ModalProps> = (props) => {
  const { skin, ...rest } = props;
  const { isMobile } = useEnvironment();

  return (
    <Dialog
      {...rest}
      forceBWTheme={true}
      wiredToSiteColors={false}
      className={classes.root}
      childrenWrapperClassName={classes.dialogContent}
      contentClassName={st(
        classes.contentWrapper,
        { skin: skin!, mobile: isMobile },
        rest.className,
      )}
    />
  );
};

Modal.displayName = 'Modal';
Modal.Buttons = (props) => (
  <div className={classes.buttons}>{props.children}</div>
);

Modal.Header = ({ title, subtitle }) => (
  <div className={classes.header}>
    <Text
      typography={TextTypography.smallTitle}
      className={classes.title}
      tagName="h1"
    >
      {title}
    </Text>
    {subtitle ? (
      <Text
        typography={TextTypography.runningText}
        className={classes.subtitle}
        tagName="h2"
      >
        {subtitle}
      </Text>
    ) : null}
  </div>
);
